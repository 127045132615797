.loader.center {
  margin: auto;
  max-width: 400px;
  min-width: 150px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
}
div.loader.center {
  font-size: 100px;
}

.loaded {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 10px;
}
