* {
    margin: 0;
    padding: 0;
    outline: inherit;
    box-sizing: border-box;
  }
  
  a {
    text-decoration: none;
    color: #333333;
  }
  
  ul li {
    list-style: none;
  }
  
  body {
    font-family: "Lato" !important;
    font-size: 16px !important;
    font-weight: 400;
    line-height: normal;
    letter-spacing: normal;
    color: #333333;
  }

  p {
    line-height: normal;
  }

  html {
    font-size: 16px !important;
  }
  
  .row {
    margin: 0rem;
  }
  
  [class*=col-] {
    padding: 0rem;
  }
  
  a:hover {
    text-decoration: none;
    color: inherit;
  }
  
  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6, p {
    margin-bottom: 0rem;
  }
  
  .btn-block + .btn-block {
    margin-bottom: 0rem;
  }
  
  .btn-block + .btn-block {
    margin-top: 0rem;
  }
  
  button, input, optgroup, select, textarea {
    font-family: "Lato";
  }
  
  .wid-100 {
    width: 100%;
  }
  
  .pt_1 {
    padding-top: 0.25rem;
  }
  
  .pt_2 {
    padding-top: 0.5rem;
  }
  
  .pt_3 {
    padding-top: 0.75rem;
  }
  
  .pt_4 {
    padding-top: 1rem;
  }
  
  .pt_5 {
    padding-top: 1.25rem;
  }
  
  .pt_6 {
    padding-top: 1.5rem;
  }
  
  .pt_7 {
    padding-top: 1.75rem;
  }
  
  .pt_8 {
    padding-top: 2rem;
  }
  
  .pb_1 {
    padding-bottom: 0.25rem;
  }
  
  .pb_2 {
    padding-bottom: 0.5rem;
  }
  
  .pb_3 {
    padding-bottom: 0.75rem;
  }
  
  .pb_4 {
    padding-bottom: 1rem;
  }
  
  .pb_5 {
    padding-bottom: 1.25rem;
  }
  
  .pb_6 {
    padding-bottom: 1.5rem;
  }
  
  .pb_7 {
    padding-bottom: 1.75rem;
  }
  
  .pb_8 {
    padding-bottom: 2rem;
  }
  
  .mt_1 {
    margin-top: 0.25rem;
  }
  
  .mt_2 {
    margin-top: 0.5rem;
  }
  
  .mt_3 {
    margin-top: 0.75rem;
  }
  
  .mt_4 {
    margin-top: 1rem;
  }
  
  .mt_5 {
    margin-top: 1.25rem;
  }
  
  .mt_6 {
    margin-top: 1.5rem;
  }
  
  .mt_7 {
    margin-top: 1.75rem;
  }
  
  .mt_8 {
    margin-top: 2rem;
  }
  
  .mb_1 {
    margin-bottom: 0.25rem;
  }
  
  .mb_2 {
    margin-bottom: 0.5rem;
  }
  
  .mb_3 {
    margin-bottom: 0.75rem;
  }
  
  .mb_4 {
    margin-bottom: 1rem;
  }
  
  .mb_5 {
    margin-bottom: 1.25rem;
  }
  
  .mb_6 {
    margin-bottom: 1.5rem;
  }
  
  .mb_7 {
    margin-bottom: 1.75rem;
  }
  
  .mb_8 {
    margin-bottom: 2rem;
  }
  
  .mfull_1 {
    margin: 0.25rem;
  }
  
  .mfull_2 {
    margin: 0.5rem;
  }
  
  .mfull_3 {
    margin: 0.75rem;
  }
  
  .mfull_4 {
    margin: 1rem;
  }
  
  .mfull_5 {
    margin: 1.25rem;
  }
  
  .mfull_6 {
    margin: 1.5rem;
  }
  
  .mfull_7 {
    margin: 1.75rem;
  }
  
  .mfull_8 {
    margin: 2rem;
  }
  
  a, span {
    display: inline-block;
  }
  
  .border-inherit {
    border: inherit !important;
  }
  
  label, .form-group {
    margin-bottom: 0rem;
  }
  
  @font-face {
    font-family: "Lato";
    src: url("./assets/fonts/Lato-Bold.woff") format("woff"), url("./assets/fonts/Lato-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: "Lato";
    src: url("./assets/fonts/Lato-Semibold.woff") format("woff"), url("./assets/fonts/Lato-Semibold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: "Lato";
    src: url("./assets/fonts/Lato-Medium.woff") format("woff"), url("./assets/fonts/Lato-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: "Lato";
    src: url("./assets/fonts/Lato-Black.woff") format("woff"), url("./assets/fonts/Lato-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
  }
  @font-face {
    font-family: "Lato";
    src: url("./assets/fonts/Lato-Light.woff") format("woff"), url("./assets/fonts/Lato-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: "Lato";
    src: url("./assets/fonts/Lato-Thin.woff") format("woff"), url("./assets/fonts/Lato-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
  }
  @font-face {
    font-family: "Lato";
    src: url("./assets/fonts/Lato-Regular.woff") format("woff"), url("./assets/fonts/Lato-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }

  @media (min-width: 768px) {
    .online_request .request_service .login_details {
      height: calc(100vh - 5rem);
    }
  }


  .ui.grid {
    margin: 0px;
  }
  .ui.grid>.column:not(.row) {
    padding: 0px;
  }

  .border-inherit {
    border: inherit;
  }

  .ui.fluid.input>input {
    width: auto !important;
  }

  .ui.grid>* {
    padding: 0px;
  }

  .text-right {
    text-align: right;
  }