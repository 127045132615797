.slot-container {
  width: 100%;
}
.slots-message {
  padding-top: 20px;
  text-align: center;
}
.slot {
  border: 1px solid blue;
  border-radius: 6px;
  margin: auto;
  margin-bottom: 12px;
  padding: 8px;
  width: 130px;
  text-align: center;
  transition: background-color 0.2s 0s;
}
.slot:hover {
  background-color: lightblue;
  color: black;
  cursor: pointer;
}
.selected-slot {
  background-color: blue !important;
  color: white !important;
}
.schedule-day-picker .rdtPicker {
  border: none !important;
}
input[name=scheduledTime] {
  display: none;
}
/* Desktop view */
@media screen and (min-width: 500px){ 
  .schedule-day-picker {
    width: 50%;
    padding: 10px;
    float: left;
  }
  .schedule-time-picker {
    width: 50%;
    padding: 10px;
    float: left;
  }
}
/* Mobile view */
.schedule-day-picker {
  display: block;
  margin: auto;
  max-width: 80%;
}
.schedule-time-picker {
  padding: 10px;
  display: block;
}
.clear-both {
  clear: both;
}
.schedule-date {
  text-align: center;
  padding-top: 12px;
  margin-bottom: 12px;
}
.slot-selectors {
  width: 100%;
  height: 235px;
  overflow: auto;
}
.schedule-selector {
  padding-bottom: 24px;
}
.timezone {
  text-align: center;
}
.schedule-selector .qus-title {
  font-size: 18px;
}
.rdtStatic.rdtOpen .rdtPicker {
  margin: auto !important;
}