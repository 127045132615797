.bold{
  font-weight: bold;    
}

.t-right{
  text-align: right !important;
}

/* .request-service .actions.use-current-location .bs-btn{
  padding: 1rem 0rem !important
} */

/* .request-service .actions.cancel-issue .bs-btn{
  padding: 2rem 0rem !important
}   */

.Demo__map-maker-icon {
  color: #FD6C6C;
}

.Demo__github-icon {
  font-size: 24px;
}

.Demo__github-link {
  color: #262626;
  font-size: 20px;
}

.Demo__github-link:hover,
.Demo__github-link:active {
  color: #4078c0;
  text-decoration: none;
}

.Demo__suggestion-icon {
  margin-right: 8px;
}

/*.Demo__search-bar-container {
  width: 90%;
  max-width: 500px;
  margin: 40px auto 0;
}*/

.Demo__search-input-container {
  position: relative;
}

.Demo__search-input,
.Demo__search-input:focus,
.Demo__search-input:active {
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
  border: honeydew;
  display: block;
  width: 100%;
  padding: 16px;
  font-size: 16px;
  border-radius: 2px;
  outline: none;
}

.Demo__search-bar-container {
  position: relative;
}

.Demo__autocomplete-container {
  /* border-bottom: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6; */
  /* box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  border-radius: 0 0 2px 2px; */
  position: absolute;
  top: 60px;
  left: 0px;
  right: 0px;
  z-index: 2;
  border-radius: 4px;
  box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  border: inherit;
  padding-left: 20px;
  overflow-y: scroll;
}

.Demo__autocomplete-container::-webkit-scrollbar {
  width: 0px;
}
.Demo__autocomplete-container > div {
  border-radius: 4px;
}

.Demo__suggestion-item {
  /* padding: 8px; */
  padding: 12px 0px;
  text-align: left;
  cursor: pointer;
  border-bottom: 1px solid #e1e1e1;
  border-radius: 0px !important;
  font-size: 14px;
  font-weight: normal;
  color:#333333;
  
}
.Demo__suggestion-item i {
  font-size: 20px;
}

.Demo__suggestion-item--active, .Demo__suggestion-item:hover {
  background-color: #ffffff;
} 
.Demo__dropdown-footer {
  display: flex;
  justify-content: flex-end;
  padding: 4px;
}

.Demo__dropdown-footer-image {
  display: inline-block;
  width: 150px;
}

.Demo__spinner {
  color: #18bc9c;
  font-size: 30px;
}

.Demo__error-message {
  color: red;
}

.Demo__geocode-result-header {
  font-size: 20px;
  color: #222222;
}

@media (max-width: 480px) {
  .Demo__search-bar-container .autocomplete-container {
    text-align: left;
  }
}

@media (min-width: 768px) {
  .Demo__search-bar-container .alert {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    -webkit-animation: fadein .75s;
    -moz-animation: fadein .75s;
    -ms-animation: fadein .75s;
    -o-animation: fadein .75s;
    animation: fadein .75s;
  }

}

/* .review_fleet .form-group .bs-label{
  top: 1rem !important;
} */

/* .css-2b097c-container > div ~ div {
  z-index: 2;
} */